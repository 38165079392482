<template>
  <div class="sales-assistant-portal">
    <!-- 头部搜索栏 -->
    <div class="header">
      <div class="name">{{ userInfo.name }}</div>
    </div>
      <FilterComponent @goSearch="goSearch"></FilterComponent>
    <div :key="key">
      <van-pull-refresh
        v-model="isLoading"
        class="sales-assistant-portal-c"
        @refresh="onRefresh"
      >
        <!-- 方案 -->
        <Programme :rolesInfo="rolesInfo" :filterParams="filterParams"></Programme>
        <!-- 常用功能 -->
        <CommmonFunction
          ref="commmonFunction"
          :rolesInfo="rolesInfo"
        ></CommmonFunction>
        <!-- 代办事项 -->
        <ToDo
          id="todo"
          ref="todo"
          :rolesInfo="rolesInfo"
          :filterParams="filterParams"
          :toDoTime="toDoTime"
        ></ToDo>
        <!-- 业绩排行 -->
        <PerformanceRank v-if="showRank" :rolesInfo="rolesInfo" :filterParams="filterParams"></PerformanceRank>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Programme from './components/Programme.vue'
import CommmonFunction from './components/CommmonFunction.vue'
import PerformanceRank from './components/PerformanceRank.vue'
import ToDo from './components/ToDo.vue'
import homeIcon from '@/images/home_icon.png'
import userIcon from '@/images/user_icon.png'
import homeIconActive from '@/images/home_icon_active.png'
import userIconActive from '@/images/user_icon_active.png'
import FilterComponent from './components/FilterComponent.vue'
import dayjs from 'dayjs'
export default {
  name: 'sales-assistant-portal',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'sales-assistant-portal')
    })
  },
  components: {
    Programme,
    CommmonFunction,
    ToDo,
    PerformanceRank,
    FilterComponent
  },
  data() {
    return {
      active: 'home',
      homeIcon,
      userIcon,
      userIconActive,
      homeIconActive,
      showPopover: false,
      toDoTime: '',
      rolesInfo: {},
      filterParams: {},
      isLoading: false,
      key: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    showRank() {
      // // 当角色包含店长 顾问 组长时展示
      // const { roleLogos = [] } = this.userInfo
      // return roleLogos.some((item) => {
      //   return [
      //     ROLES.SALES,
      //     ROLES.SHOWOWNER,
      //     ROLES.MASTER,
      //     ROLES.BUDDY,
      //   ].includes(item)
      // })

      return true
    },
  },
  mounted() {
    // this.onRefresh()
  },
  activated() {
    this.onRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    // 头部查询
    goSearch(data) {
      this.filterParams = data
      this.onRefresh()
    },
    onChange(name) {
      this.activeTabVal = 0
      this.$router
        .push({
          name: `${name}`,
          query: {
            replace: true,
          },
        })
        .then(() => {
          this.$nextTick(() => {
            this.active = name
          })
        })
    },
    // 跳转交付单
    checkMore({ start, end }, { roleCode }) {
      this.$router.push({
        path: '/deliver-manage',
        query: {
          bookDeliveryTimeStart: start,
          bookDeliveryTimeEnd: end,
          deliveryStatuses: ['4000006', '4000007', '4000008', '4000010'],
          roleCode,
        },
      })
    },
    onRefresh() {
      this.refreshTime = dayjs().format('HH:mm')
      this.isLoading = true
      this.key = String(Math.ceil(Math.random() * 10000))
      this.isLoading = false
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  background: #fff;
  padding: 0 16px;
  position: sticky;
  top: 0;
  z-index: 10;
  // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
  }
}
.sales-assistant-portal {
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  color: @black;
  .sales-assistant-portal-c {
    padding: 12px 16px;
    /deep/ .van-pull-refresh__track {
      height: auto;
    }
  }
  .common-block:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
</style>
